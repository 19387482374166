<template>
  <div>
    <!-- Main Card - Displayed initially -->
    <v-card v-if="!showInfoCard" outlined>
      <v-img
        src="@/assets/standings_static/AC_S5_Header.jpg"
        :height="$vuetify.breakpoint.smAndDown ? '90px' : '200px'"
        gradient="to top right, rgba(0,0,0,.5), rgba(255,165,0,.5)"
        class="align-end"
      >
        <v-card-title class="white--text pb-0 d-block">
          <p
            :class="$vuetify.breakpoint.smAndDown ? 'font-weight-black text-h5 text-wrap d-block mb-0' : 'font-weight-black text-h4 text-wrap d-block mb-0'"
            style="word-break: normal !important"
          >
            Sezon 5 - Assetto Corsa [rezumat]
          </p>
          <p :class="$vuetify.breakpoint.smAndDown ? 'body-2 mt-0 blue--text' : 'body-2 mt-0 blue--text '">Assetto Corsa</p>
          <p class="body-2 text-justify d-none d-md-block grey--text text--lighten-3" style="word-break: normal !important">
            Sezonul istoric care a marcat explozia numarului de participanti in curse si de membri in comunitatea RLR. Atat datorita alegerii unui model de legenda (BMW M3 E30 DTM) cat si suprapunerii cu o perioada "legendara", pandemia COVID.
          </p>
        </v-card-title>
      </v-img>

      <v-card-text>
        <v-container>
          <v-row>
            <!-- Left Column -->
            <v-col cols="6">
              <!-- Row 1: Competition Duration -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-calendar-month</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Durata competiției</v-list-item-title>
                    <span>15 martie 2020 <v-icon small>mdi-arrow-right</v-icon> 17 mai 2020</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 2: Cars -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-car</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Mașina</v-list-item-title>
                    <span>BMW M3 E30 DTM</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 3: Participation -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-account-group</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Participare</v-list-item-title>
                    <span>280 mașini înscrise</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <!-- Right Column -->
            <v-col cols="6">
              <!-- Row 1: Champion -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon style="color: #daa520;">mdi-trophy</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Campion</v-list-item-title>
                    <span>Cristian Berindea</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 2: Format -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-timer</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Etape</v-list-item-title>
                    <span>Cele mai bune 8 din 10</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 3: Prizes -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-car-brake-parking</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Premii</v-list-item-title>
                    <span>Oferite de BMW Romania, Aqirys, Motul, Crama Histria</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

            </v-col>
          </v-row>
        </v-container>
</v-card-text>


      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="6" sm="3">
            <v-btn dark color="green darken-1" @click="showInfoCard = true">
              CLASAMENT <v-icon right>mdi-information-outline</v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6" sm="3" :align="$vuetify.breakpoint.smAndUp ? 'right' : 'center'">
            <v-btn dark color="blue darken-1" href="https://forum.simrace.ro/index.php?forums/arhivele-nationale.55/" target="_blank">
              Arhive <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <!-- New Card with Detailed Information and an Image - Shown when "INFO" is clicked -->
    <v-card v-else outlined class="ma-4 pa-4">
      <!-- Add image to the info card -->
      <v-card-title class="headline">Detalii competiție</v-card-title>
      <v-card-text style="text-align: justify;">
        START (by Vic): Să ridice mâna cine s-a decis că mașina asta de fapt e foarte cool pentru racing! După două curse de test am deosebita plăcere să vă invit la prima etapă oficială a Sezonului 5, care va avea loc pe binecunoscutul Red Bull Ring. Va fi o reală provocare pentru piloții RLR să stăpânească M3-ul pe un circuit atât de tehnic, care combină zone de frânare puternică cu câteva viraje rapide ce se pot dovedi dificile într-o mașină fără prea multă apăsare aerodinamică. <br>
        Primele puncte se pun în joc duminică, 15 martie, dar ca să le puteți pretinde trebuie mai întâi să vă precalificați obținând un timp de Top 30 pe server-ul nostru. Toate detaliile le găsiți mai jos; let's do this!
      </v-card-text>
      <v-img
        src="@/assets/standings_static/AC_S5_Calendar.jpg"
        class="align-end"></v-img>
      <v-card-text style="text-align: justify;">
        FINAL (by Siclo): Al cincilea sezon Racing League Romania powered by BMW s-a încheiat odată cu disputarea finalei de la Brands Hatch. Acțiunea din campionat a totalizat aproape 250 de mii de vizualizări de-a lungul celor 10 etape programate în al cincilea sezon. Pentru a doua oară în istoria RLR, un pilot reușește “dubla”, Cristian Berindea venind astfel alături de Victor Nicolae în fruntea ierarhiei all-time. Finala poate fi văzută în reluare marți, 19 mai, pe Telekom Sport 3, de la ora 17:00.
        <br><br>
        Într-o demonstrație de forță în materie de sportivitate și racing spectaculos, ultima etapă din Racing League Romania powered by BMW a reprezentat un demn ultim tribut adus legendarului BMW M3 E30 DTM după 10 weekend-uri de curse virtuale. Cel mai mare campionat de sim racing din România și-a încoronat un campion ce a avut de înfruntat opoziția a mai bine de 280 de piloți înscriși în acest sezon.
      </v-card-text>
      <v-card-text style="text-align: justify;">
        <p>Victoriile de la Brands Hatch vor fi răsplătite de către PC Coolers, Motul și Crama Histria - parteneri ai Racing League Romania powered by BMW. Câștigătorii de manșă vor primi un scaun de gaming Playseat Challenge, respectiv o carcasă PC Aqirys. Motul va oferi vouchere pentru schimburi de ulei sau obiecte de merchandising învingătorilor de curse, iar Crama Histria va recompensa ocupanții podiumurilor din fiecare manșă cu gusturile alese ale vinurilor dobrogene.</p>
        <br>
        La final de sezon, BMW România va premia primii trei piloți din clasament precum și echipa câștigătoare în cadrul galei de premiere a campionatului. Data și locația se vor stabili odată ce măsurile de prevenție ale autorităților pot fi respectate.<br><br>
        Clasamentul este mult prea lung pentru a fi postat aici, asa ca apasa butonul ce te va duce spre platforma RLR (V1).<br>
      </v-card-text>

      <v-card-actions>              
        <v-row no-gutters>
          <v-col cols="6" sm="3">
            <v-btn color="blue darken-1" @click="showInfoCard = false">
              <v-icon left>mdi-arrow-left</v-icon> Înapoi
            </v-btn>
          </v-col>
        <v-spacer></v-spacer>  
        <v-col cols="6" sm="3" :align="$vuetify.breakpoint.smAndUp ? 'right' : 'center'">
          <v-btn dark color="green darken-1" href="https://rlr.simrace.ro/standings/?season=5&race=10" target="_blank">
            Clasament S5 <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AC_Sezon5',
  data() {
    return {
      showInfoCard: false, // Track whether the info card is shown
    };
  },
};
</script>

<style scoped>
/* Add any custom styles here if needed */
</style>