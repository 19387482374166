<template>
  <div>
    <!-- Main Card - Displayed initially -->
    <v-card v-if="!showInfoCard" outlined>
      <v-img
        src="@/assets/standings_static/AC_S6_Header.jpg"
        :height="$vuetify.breakpoint.smAndDown ? '90px' : '200px'"
        gradient="to top right, rgba(0,0,0,.5), rgba(255,165,0,.5)"
        class="align-end"
      >
        <v-card-title class="white--text pb-0 d-block">
          <p
            :class="$vuetify.breakpoint.smAndDown ? 'font-weight-black text-h5 text-wrap d-block mb-0' : 'font-weight-black text-h4 text-wrap d-block mb-0'"
            style="word-break: normal !important"
          >
            Sezon 6 - Assetto Corsa [rezumat]
          </p>
          <p :class="$vuetify.breakpoint.smAndDown ? 'body-2 mt-0 blue--text' : 'body-2 mt-0 blue--text '">Assetto Corsa</p>
          <p class="body-2 text-justify d-none d-md-block grey--text text--lighten-3" style="word-break: normal !important">
            Sezonul experiment, unde s-a incercat rularea in paralel a doua serii valorice PRO si SEMI-PRO, cu posibilitatea promovarii/retrogradarii intre cele doua la fiecare doua etape.
          </p>
        </v-card-title>
      </v-img>

      <v-card-text>
        <v-container>
          <v-row>
            <!-- Left Column -->
            <v-col cols="6">
              <!-- Row 1: Competition Duration -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-calendar-month</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Durata competiției</v-list-item-title>
                    <span>6 iulie 2020 <v-icon small>mdi-arrow-right</v-icon> 25 oct 2020</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 2: Cars -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-car</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Mașina</v-list-item-title>
                    <span>BMW M3 E92 GT2</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 3: Participation -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-account-group</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Participare</v-list-item-title>
                    <span>197 mașini înscrise</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <!-- Right Column -->
            <v-col cols="6">
              <!-- Row 1: Champion -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon style="color: #daa520;">mdi-trophy</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Campion</v-list-item-title>
                    <span>Victor Taraș</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 2: Format -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-timer</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Etape</v-list-item-title>
                    <span>10 din 10</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 3: Prizes -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-car-brake-parking</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Premii</v-list-item-title>
                    <span>Oferite de BMW Romania, Aqirys, Motul, Crama Histria, Racing Sim, Smart Driving</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

            </v-col>
          </v-row>
        </v-container>
</v-card-text>


      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="6" sm="3">
            <v-btn dark color="green darken-1" @click="showInfoCard = true">
              CLASAMENT <v-icon right>mdi-information-outline</v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6" sm="3" :align="$vuetify.breakpoint.smAndUp ? 'right' : 'center'">
            <v-btn dark color="blue darken-1" href="https://forum.simrace.ro/index.php?forums/arhivele-nationale.55/" target="_blank">
              Arhive <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <!-- New Card with Detailed Information and an Image - Shown when "INFO" is clicked -->
    <v-card v-else outlined class="ma-4 pa-4">
      <!-- Add image to the info card -->
      <v-card-title class="headline">Detalii competiție</v-card-title>
      <v-img
        src="@/assets/standings_static/AC_S6_Calendar.jpg"
        class="align-end"></v-img>
      <v-card-text style="text-align: justify;">
        <p>FINAL (by Siclo): Victor Taraș a rezistat șarjei ofensive a lui Mihai Șuțeanu în finala de la Imola, un avans de doar trei puncte asigurându-i titlul de campion al sezonului al șaselea din Racing League Romania powered by BMW. În pofida absenței din primele două etape ale Ligii PRO, Walk Racing s-a impus în clasamentul echipelor încă din campania de debut a acestei grupări.
        <br><br>
        Cea de-a doua campanie a Racing League Romania în 2020 a ajuns la final cu o luptă pasionantă pe Autodromo Enzo e Dino Ferrari, traseul italan de Grand Prix fiind gazda unei runde decisive în care patru piloți și trei echipe au vizat titlurile de campioni. Emanuel Gaczella și Andrei Bechir au ieșit din calcule la finele primei manșe, însă Mihai Șuțeanu a dat totul pentru a-i lua puncte lui Victor Taraș în ambele curse. Nu a fost însă suficient, Taraș păstrând un mic avans, în pofida unei penalizări post-cursă. Astfel, tânărul brașovean intră în galeria campionilor RLR, alături de Victor Nicolae, Alex Cascatău și Cristian Berindea.
        <br><br>
        Cristian Berindea a debutat în Racing League Romania în sezonul al patrulea și a obținut titlul la prima tentativă. Ulterior, a rămas reperul absolut de viteză în campania de primăvară cu BMW M3 E30, însă trecerea la noua mașină pentru actuala stagiune a deschis pur și simplu disputa pentru podium către un număr mare de piloți. Victor Taraș a fost aproape de mai multe victorii, însă mereu s-a interpus un adversar și astfel câștigă titlul după un parcurs similar cu ceea ce vedem la Joan Mir în MotoGP.
        
        Debutul în acest sezon însemna o trecere de cel puțin două etape prin Liga Semi Pro, ceea ce i-a făcut pe Șuțeanu și Gaczella să ajungă să se lupte pentru supremație abia de la Oschersleben. Cei doi au făcut destul pentru a aduce titlul echipelor în premieră pentru Walk Raicng, reușind o medie de puncte per etapă superioară campionului. Contabilizarea incidentelor printr-un sistem centralizat de rating i-a scos din ecuație pe Victor Nicolae (prim lider al sezonului), Radu Dumitrescu și Bogdan Moldovan, în timp ce alții pur și simplu au admis că sunt tot mai mulți sim raceri dedicați, ce ajung pe merit să devanseze vechea gardă.
        
        Niciun fost campion al seriei nu a încheiat așadar în primii 6 la general, acolo unde Andrei Bechir se putea considera veteran la finele celui de-al treilea său sezon, față de toți ceilalți care au la activ doar cursele RLR din 2020. Totodată, acest sezon a rescris un record de învingători diferiți, Cascatău fiind al optulea sim racer care reușește să-și treacă în cont o manșă.
        <br><br>
        Ultima etapă de campionat nu înseamnă că Racing League Romania powered by BMW intră în vacanță. Luna noiembrie aduce mai întâi verdictul juriului în cadrul Gaming Video Awards, unde RLR este nominalizată la categoria ”cel mai bun stream” (locul al doilea din 33 de propuneri la faza votului publicului), apoi un eveniment demonstrativ în programul Bucharest Gaming Week 2020.
        <br><br>
        Totodată, contextul sanitar este determinant pentru a stabili momentul propice pentru gala de premiere a Racing League Romania, dar și pentru desfășurarea unui shootout între primii trei clasați (Victor Taraș, Mihai Șuțeanu și Andrei Bechir) pe Motorpark România la bordul modelelor BMW 228i.   
      </p>
      </v-card-text>

      <v-card-actions>              
        <v-row no-gutters>
          <v-col cols="6" sm="3">
            <v-btn color="blue darken-1" @click="showInfoCard = false">
              <v-icon left>mdi-arrow-left</v-icon> Înapoi
            </v-btn>
          </v-col>
        <v-spacer></v-spacer>  
        <v-col cols="6" sm="3" :align="$vuetify.breakpoint.smAndUp ? 'right' : 'center'">
          <v-btn dark color="green darken-1" href="https://rlr.simrace.ro/standings/?season=6&race=10" target="_blank">
            Clasament S6 <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AC_Sezon6',
  data() {
    return {
      showInfoCard: false, // Track whether the info card is shown
    };
  },
};
</script>

<style scoped>
/* Add any custom styles here if needed */
</style>