<template>
  <div>
    <!-- Main Card - Displayed initially -->
    <v-card v-if="!showInfoCard" outlined>
      <v-img
        src="@/assets/standings_static/AC_S1_Header.jpg"
        :height="$vuetify.breakpoint.smAndDown ? '90px' : '200px'"
        gradient="to top right, rgba(0,0,0,.5), rgba(255,165,0,.5)"
        class="align-end"
      >
        <v-card-title class="white--text pb-0 d-block">
          <p
            :class="$vuetify.breakpoint.smAndDown ? 'font-weight-black text-h5 text-wrap d-block mb-0' : 'font-weight-black text-h4 text-wrap d-block mb-0'"
            style="word-break: normal !important"
          >
            Sezon 1 - Assetto Corsa [rezumat]
          </p>
          <p :class="$vuetify.breakpoint.smAndDown ? 'body-2 mt-0 blue--text' : 'body-2 mt-0 blue--text '">Assetto Corsa</p>
          <p class="body-2 text-justify d-none d-md-block grey--text text--lighten-3" style="word-break: normal !important">
            Odata cu sosirea lui Victor Nicolae si avantul organizatoric pe care l-a adus, ne lansam intr-o noua serie de competitii virtuale. Migram de la LFS la Assetto Corsa.
          </p>
        </v-card-title>
      </v-img>

      <v-card-text>
        <v-container>
          <v-row>
            <!-- Left Column -->
            <v-col cols="6">
              <!-- Row 1: Competition Duration -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-calendar-month</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Durata competiției</v-list-item-title>
                    <span>30 septembrie 2018 <v-icon small>mdi-arrow-right</v-icon> 2 decembrie 2018</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 2: Cars -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-car</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Mașini</v-list-item-title>
                    <span>Clasa GT3</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 3: Participation -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-account-group</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Participare</v-list-item-title>
                    <span>16 mașini înscrise</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <!-- Right Column -->
            <v-col cols="6">
              <!-- Row 1: Champion -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon style="color: #daa520;">mdi-trophy</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Campion</v-list-item-title>
                    <span>Victor Nicolae</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 2: Format -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-timer</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Etape</v-list-item-title>
                    <span>Cele mai bune 8 din 10</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 3: Prizes -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-car-brake-parking</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Premii</v-list-item-title>
                    <span>Prietenii care incă durează și astăzi.</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

            </v-col>
          </v-row>
        </v-container>
</v-card-text>


      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="6" sm="3">
            <v-btn dark color="green darken-1" @click="showInfoCard = true">
              CLASAMENT <v-icon right>mdi-information-outline</v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6" sm="3" :align="$vuetify.breakpoint.smAndUp ? 'right' : 'center'">
            <v-btn dark color="blue darken-1" href="https://forum.simrace.ro/index.php?forums/arhivele-nationale.55/" target="_blank">
              Arhive <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <!-- New Card with Detailed Information and an Image - Shown when "INFO" is clicked -->
    <v-card v-else outlined class="ma-4 pa-4">
      <!-- Add image to the info card -->
      <v-img
        src="@/assets/standings_static/AC_S1_Calendar.jpg"
        class="align-end"></v-img>
      <v-card-title class="headline">Detalii competiție</v-card-title>
      <v-card-text style="text-align: justify;">
        DEBUT DE SEZON (by Vic): Prima cursă a sezonului va avea loc pe 30 septembrie. Italia ne găzduiește bolizii, iar organizatorii anunță că tribunele de la Mugello vor fi pline. Fanii au răbdat ani buni ca vitejii piloți români să se întreacă din nou dar anul acesta visul devine realitate!
        <br>
        Se anunță o vreme bună, călduroasă la Mugello în ziua cursei, cu o temperatură în aer așteptată de 27 de grade Celsius. Piloții sunt flămânzi de victorie, vremea e frumoasă iar mașinile au trecut prin ultimele verificări... scena e pregătită!
      </v-card-text>
      <v-card-text style="text-align: justify;">
        FINAL DE SEZON (by Luc): Si primul sezon al Micului Circ s-a incheiat. Desi grila a fost modesta in ce priveste numarul de piloti, suspansul a fost prezent pana in ultima clipa, nimeni nestiind cine e campionul inaintea calculelor oficiale. Destinul a jucat si el un rol in toata povestea, #78 C.Luci ratand complet prima cursa a serii. Daca ar fi adus masina acasa, ne-am fi uitat probabil la un alt clasament. Insa la sfarsitul serii, si cu doar 4p avans, #01 N.Victor reuseste sa agate titlul de campion! Felicitari!
        <br>
        <p>Mai jos nu avem surprize, #09 C.Luchian pastreaza ultima treapta a podiumului si scapa basma curata de data asta in fata rivalului #25 P.Andrei. La randul sau, Andrei are propriul rival, #74 S.Cristian, care nu a reusit totusi sa "ne rupa", si va trebui sa se multumeasca cu locul 5 la general. Cel mai mare progres din acest sezon (in opinia subsemnatului) l-a facut totusi #76 B.Marton. Daca in primele etape avea cateva secunde in plus pe tur, in ultima parte a campionatului s-a apropiat remarcabil, ba chiar a intrat in timpii plutonului non-alienilor. Bravo ! Mai jos, pe 8 il gasim pe #64 Z.Catalin, care nici el nu a avut cea mai fasta seara pe Spa. Top10 este incheiat de #07 I.Horia, care a reusit sa ii fure pozitia lui #73 P.Gabriel cu o prestatie rapida (dar nu lipsita de evenimente) pe Spa.</p>
        <p>Asta a fost tot pentru Sezonul 1. Cu totii am progresat, cu totii am gresit, cu totii am si invatat (sper), si cu totii ne dorim un sezon 2 mai animat si mai "curat" din punct de vedere al incidentelor de cursa. Felicitari participantilor si va multumim pentru prezenta. Profitati de vacanta, ne vedem gata de curse la inceputul anului urmator. Traiasca RLR!</p>
      </v-card-text>
      <v-img
        src="@/assets/standings_static/AC_S1_Final.jpg"
        class="align-end"></v-img>
      <v-card-actions>
        <v-btn color="blue darken-1" @click="showInfoCard = false">
          <v-icon left>mdi-arrow-left</v-icon> Înapoi
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AC_Sezon1',
  data() {
    return {
      showInfoCard: false, // Track whether the info card is shown
    };
  },
};
</script>

<style scoped>
/* Add any custom styles here if needed */
</style>