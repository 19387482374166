<template>
  <v-layout row wrap class="my-5 mx-0" align-center>
    <v-flex xs12 offset-xs0 sm12 offset-sm0 md10 offset-md1 lg8 offset-lg2>
      <v-card outlined>
        <v-btn text color="blue" :to="`/season/${event.season._id}?tab=calendar`" class="mt-10 ml-5"
          ><v-icon>mdi-chevron-left</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">Inapoi</span>
          </v-btn
        >
        <v-card-title class="font-weight-black text-h4 text-uppercase mb-0">
          {{ event.title }}
          <v-spacer></v-spacer>
          <v-dialog v-model="rsvpDialog" persistent max-width="400" v-if="rsvpDateGood">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on" :disabled="!rsvpOpen || driverSuspended">
                <span v-if="driverSuspended">Suspendat</span>
                <span v-else-if="rsvpCar">Modifică RSVP</span>
                <span v-else>Confirmă prezența</span>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="headline">
                Vei fi prezent la cursa?
              </v-card-title>

              <v-card-text
                >Alege mașina în care vei concura la cursă și apasă 'Prezent' dacă vei veni la cursă. Te poți răzgândi
                oricând apăsând 'Absent'.

                <v-select
                  :items="userAvailableCars"
                  label="Masina"
                  v-model="rsvpDialogCar"
                  return-object
                  @change="rsvpCarChanged"
                >
                  <template v-slot:selection="data">
                    #{{data.item.raceNumber}} {{data.item.carName}}
                  </template>
                  
                  <template v-slot:item="data">
                    #{{data.item.raceNumber}} {{data.item.carName}}
                  </template>
                </v-select>

                <v-select
                  v-if="rsvpDialogCar && carNeedsFirstDriverSet"
                  :disabled="!rsvpDialogCar"
                  :items="rsvpAvailableDrivers"
                  item-value="_id"
                  v-model="firstDriver"
                  label="Pilotul care se conectează primul la server"
                  return-object
                >
                  <template v-slot:selection="data">
                    {{data.item.profile.firstName}} {{data.item.profile.lastName}}
                  </template>
                  
                  <template v-slot:item="data">
                    {{data.item.profile.firstName}} {{data.item.profile.lastName}}
                  </template>
                </v-select>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="cancelRSVPDialog()">
                  Anuleaza
                </v-btn>
                <v-btn color="red darken-1" dark @click="resetRSVPCar()">
                  Absent
                </v-btn>
                <v-btn
                  color="green darken-1"
                  :dark="!!rsvpDialogCar"
                  :outlined="!rsvpDialogCar"
                  :disabled="!rsvpDialogCar"
                  @click="confirmRSVP()"
                >
                  Prezent
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-spacer></v-spacer>

        <v-card-text :class="{ 'px-0': $vuetify.breakpoint.smAndDown }">
          <v-tabs color="blue" centered icons-and-text v-model="tab">
            <v-tab href="#info">Info<v-icon>mdi-information</v-icon></v-tab>
            <!-- v-tab href="#track">Circuit<v-icon>mdi-road-variant</v-icon></v-tab -->
            <v-tab href="#registrations">
              Participanți<v-icon>mdi-car-multiple</v-icon>
            </v-tab>
            <v-tab v-if="officialSessions.length > 0 || canUserEditResults" href="#results">Rezultate<v-icon>mdi-clipboard-list</v-icon></v-tab>

              <v-tab-item value="info">
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
                    <v-card-title>Circuit</v-card-title>
                    <v-list v-if="event">
                      <template>
                        <v-list-item dense>
                          <v-list-item-action><v-icon>mdi-road-variant</v-icon></v-list-item-action>

                          <v-list-item-content>
                            <span>{{ event.track.trackFamily.name }} {{ event.track.name }}</span>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense>
                          <v-list-item-action><v-icon>mdi-map-marker</v-icon></v-list-item-action>

                          <v-list-item-content>
                            <span>{{ event.track.trackFamily.country }}, {{ event.track.trackFamily.city }}</span>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                    <v-card-title>Reguli</v-card-title>
                    <v-list v-if="event">
                      <v-list-item dense>
                        <v-list-item-action>
                          <v-icon>mdi-account-group</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                          <span v-if="event.season.rules.minCarsPerTeam == event.season.rules.maxCarsPerTeam"
                            >{{ event.season.rules.minCarsPerTeam }}
                            {{ event.season.rules.minCarsPerTeam > 1 ? "mașini" : "mașină" }} pentru fiecare club</span
                          >
                          <span v-else
                            >Între {{ event.season.rules.minCarsPerTeam }} și
                            {{ event.season.rules.maxCarsPerTeam }} mașini pentru fiecare club</span
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item dense>
                        <v-list-item-action>
                          <v-icon>mdi-racing-helmet</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                          <span v-if="event.season.rules.minDriversPerCar == event.season.rules.maxDriversPerCar"
                            >{{ event.season.rules.minDriversPerCar }}
                            {{ event.season.rules.minDriversPerCar > 1 ? "piloți" : "pilot" }} în fiecare mașină</span
                          >
                          <span v-else
                            >Între {{ event.season.rules.minDriversPerCar }} si
                            {{ event.season.rules.maxDriversPerCar }} piloți în fiecare mașină</span
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item dense>
                        <v-list-item-action>
                          <v-icon>mdi-car</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                          <span>{{ event.season.rules.maxCarsPerSplit }} mașini per split</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 8">
                    <v-card-title>Program</v-card-title>
                    <v-timeline dense>
                      <v-timeline-item
                        v-for="(session, i) in event.sessions"
                        :key="i"
                        :color="timelineColor(session)"
                        small
                        class="pr-2"
                      >
                        <v-card outlined>
                      <v-sheet
                        :color="timelineColor(session)"
                      >
                            <v-card-title class="text-h6 text-uppercase font-weight-black white--text ma-0">{{session.title}}</v-card-title>
                            <v-card-subtitle class="text-subtitle-2 white--text">{{printDate(session.startDate)}}</v-card-subtitle>
                          </v-sheet>
                          <v-list dense two-line>
                            <v-list-item v-if="session.serverName!=''">
                              <v-list-item-action class="my-0 py-0">
                                <v-icon>mdi-server-network</v-icon>
                              </v-list-item-action>
                              <v-list-item-content class="my-0 py-0">
                                <strong>Server</strong><span class="text-caption">{{session.serverName}}</span>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="session.password!=''">
                              <v-list-item-action class="my-0 py-0">
                                <v-icon>mdi-key</v-icon>
                              </v-list-item-action>
                              <v-list-item-content class="my-0 py-0">
                                <template v-if="driverInRegisteredCar">
                                  <strong>Parola</strong><span class="text-caption">{{session.password}}</span>
                                </template>
                                <span v-else class="font-italic">
                                  Parola e disponibilă doar piloților înscriși
                                </span>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="session.type.code=='Q' && !session.official">
                              <v-list-item-action class="my-0 py-0">
                                <v-icon>mdi-alarm-check</v-icon>
                              </v-list-item-action>
                              <v-list-item-content class="my-0 py-0">
                                <strong>Deadline</strong><span class="text-caption">Se încheie la {{printDate ( new Date (session.startDate).getTime()+session.durationMinutes*60000)}}</span>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="session.type.code!='Q' || session.official">
                              <v-list-item-action class="my-0 py-0">
                                <v-icon>mdi-clock</v-icon>
                              </v-list-item-action>
                              <v-list-item-content class="my-0 py-0">
                                <strong>Durată</strong><span class="text-caption">{{session.durationMinutes}} de minute</span>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="session.hotstintLaps>1">
                              <v-list-item-action class="my-0 py-0">
                                <v-icon>mdi-calculator</v-icon>
                              </v-list-item-action>
                              <v-list-item-content class="my-0 py-0">
                                <strong>Calcul rezultat</strong><span class="text-caption">Medie pe {{session.hotstintLaps}} tururi consecutive valide</span>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="session.reverseGridPositions>1">
                              <v-list-item-action class="my-0 py-0">
                                <v-icon>mdi-calculator</v-icon>
                              </v-list-item-action>
                              <v-list-item-content class="my-0 py-0">
                                <strong>Grilă inversată</strong><span class="text-caption">Top {{session.reverseGridPositions}}</span>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-row>
              </v-tab-item>
              <!-- v-tab-item value="track">
                <v-row>
                  <v-col xs12 s12 md12 lg12>
                  </v-col>
                </v-row>
              </v-tab-item -->
              <v-tab-item value="registrations">
                <v-container fluid>
                  <v-row v-if="event.season.rules.prequalEnabled && entrylist.prequal.bestResult">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                      <v-icon>mdi-timer-outline</v-icon> Reper: {{ printLaptime(entrylist.prequal.bestResult) }}
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                      <v-icon>mdi-timer-off-outline</v-icon> Barem participare:
                      {{ printLaptime(entrylist.prequal.thresholdResult) }}
                    </v-col>
                  </v-row>
                  <v-row v-if="canUserDownloadEntrylist">
                    <v-col cols="12" align="left">
                      <v-btn
                        text
                        tile
                        color="green"
                        @click="downloadEntrylist()"
                      >
                        <v-icon left>mdi-download</v-icon>Descarcă entrylist
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-data-table
                        :headers="computedSeasonRegistrationsTableHeaders"
                        :items="filteredEntrylist"
                        group-by="Split"
                        disable-pagination
                        hide-default-footer
                        :header-props="{ 'disable-sort': true }"
                      >
<!-- ==========================NOOB ZONE============================ -->
                      <!--RO afisare ico masina -->
                      <!--OK <v-img v-if="event.season.rules.simulator.acronym=='iR' && (item.car.carModel.title=='Supercars Ford Mustang GT' || item.car.carModel.title=='Supercars Holden ZB Commodore')" -->
                        <template v-slot:item.car_icon="{ item }">                          
                          <v-img
                            v-if="carHasLiveryPreview(item.car.raceNumber)"
                            :src="carLiveryPreviewURL(item.car.raceNumber)"
                            width="100" 
                            height="40"
                          />
                        </template>
<!-- ===========================NOOB ZONE============================ -->
                        <template v-slot:[`item.carName`]="{ item }">
                          <kbd v-if="item.status == CAR_STATUS_WILDCARD" class="mr-1">*</kbd>
                          <kbd v-for="(carClass, kindex) in item.car.classes" :key="kindex" class="mr-1">{{carClass.acronym}}</kbd>
                          #{{item.car.raceNumber}} {{item.car.carName}}
                        </template>
                        <template v-slot:[`item.carModel`]="{ item }">
                          {{item.car.carModel.title}}
                        </template>
                        <template v-slot:[`item.prettyPrequalTime`]="{ item }">
                          {{printLaptime(item.prequal.result)}}
                        </template>
                        <template v-slot:[`item.drivers`]="{ item }">
                          <span v-if="!isTeamRace">#{{item.car.raceNumber}} </span>
                          <span v-for="driver in item.drivers" :key="driver._id">
                            <kbd v-for="(driverClass, kindex) in getUserClasses(driver.user._id)" :key="kindex" class="mr-1">{{driverClass.acronym}}</kbd>{{driver.driverName}} <span v-if="isUserWildcard(driver.user._id)"> (Wildcard)</span>
                          </span>
                        </template>
                        <template v-slot:[`item.rsvpStatus`]="{ item }">
                          <v-dialog
                            v-model="carInfoDialog[item.car._id]"
                            max-width="700"
                            @click:outside="carInfoDialog[item.car._id] = false"
                            scrollable
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn v-if="item.status == CAR_STATUS_GREEN || item.status == CAR_STATUS_WILDCARD" color="green" dark v-on="on"
                                ><v-icon>mdi-check-circle</v-icon></v-btn
                              >
                              <v-btn v-else-if="item.status == CAR_STATUS_RED" color="red" dark v-on="on"
                                ><v-icon>mdi-close-circle</v-icon></v-btn
                              >
                              <v-btn v-else-if="item.status == CAR_STATUS_TIME" color="yellow darken-3" dark v-on="on"
                                ><v-icon>mdi-timer-off-outline</v-icon></v-btn
                              >
                              <v-btn v-else-if="item.status == CAR_STATUS_SUSPENDED" color="red" dark v-on="on"
                                ><v-icon>mdi-cancel</v-icon></v-btn
                              >
                              <v-btn v-else outlined v-on="on"><v-icon>mdi-cancel</v-icon></v-btn>
                            </template>

                            <v-card class="pb-1">
                              <v-card-title
                                ><span class="headline">{{ item.car.carName }}</span></v-card-title
                              >

                              <v-card-subtitle>
                                <span
                                  v-if="
                                    item.status == CAR_STATUS_RED ||
                                      item.status == CAR_STATUS_TIME ||
                                      item.status == CAR_STATUS_UNKNOWN ||
                                      item.status == CAR_STATUS_SUSPENDED
                                  "
                                >
                                  Mașina nu poate participa
                                </span>
                                <span v-else>Mașina poate participa</span>
                              </v-card-subtitle>

                              <v-card-text style="max-height: 300px">
                                <v-row class="mx-1" dense>
                                  <v-col
                                    :cols="$vuetify.breakpoint.smAndDown ? 12 : 6"
                                    v-for="driver in item.prequal.drivers"
                                    :key="driver._id"
                                  >
                                    <v-card elevation="0" outlined>
                                      <v-card-title class="text-h6 font-weight-regular">
                                        {{driver.driverName}}<span class="ml-1" v-if="driver.resultStatus == DRIVER_STATUS_WILDCARD">(Wildcard)</span>
                                      </v-card-title>
                                      <v-list>
                                        <v-list-item v-if="event.season.rules.prequalEnabled">
                                          <v-list-item-avatar>
                                            <v-icon
                                              v-if="driver.resultStatus != DRIVER_STATUS_TIME && driver.resultStatus != DRIVER_STATUS_SUSPENDED"
                                              color="green"
                                              >mdi-timer-outline</v-icon
                                            >
                                            <v-icon v-else color="red">mdi-timer-off-outline</v-icon>
                                          </v-list-item-avatar>

                                          <v-list-item-content>
                                            <span v-if="driver.result != 2147483647">
                                              {{printLaptime(driver.result)}}
                                              <span v-if="driver.resultStatus==DRIVER_STATUS_TIME">(Peste barem)</span>
                                            </span>
                                            <span v-else>
                                              Fără rezultat valid în precalificări
                                            </span>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-avatar>
                                            <v-icon v-if="driver.rsvpStatus && item.registeredForEvent && driver.resultStatus != DRIVER_STATUS_SUSPENDED" color="green"
                                              >mdi-clipboard-check-outline</v-icon
                                            >
                                            <v-icon v-else color="red">mdi-clipboard-alert-outline</v-icon>
                                          </v-list-item-avatar>

                                          <v-list-item-content>
                                            <span v-if="driver.rsvpStatus && item.registeredForEvent && driver.resultStatus != DRIVER_STATUS_SUSPENDED"
                                              >Prezență confirmată</span
                                            >
                                            <span v-else-if="driver.resultStatus != DRIVER_STATUS_SUSPENDED">Prezență neconfirmată</span>
                                            <span v-else>Suspendat</span>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item value="results">
                <v-container fluid>
                  <v-row align="center">
                    <v-col
                      cols="12"
                      align="center"
                    >
                      <v-btn-toggle
                        color="primary"
                        borderless
                        mandatory
                        v-model="storedResultsSession"
                      >
                        <v-btn
                          v-for="session in officialSessions"
                          :value="session._id"
                          :key="session._id"
                          @click="sessionresults = session._id"
                        >{{session.title}}</v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row align="center" class="mt-5">
                    <v-col
                      cols="12"
                      :align="sessionHashmap[sessionresults] && !sessionHashmap[sessionresults].results.length ? 'center' : 'right'"
                    >
                      <v-btn
                        color="primary"
                        :href="`/edit-session-results/${event._id}/${sessionresults}`"
                        v-if="canUserEditResults"
                      >
                        <span v-if="currentSession && currentSession.results && !currentSession.results.length"><v-icon left>mdi-upload</v-icon>Încarcă rezultate</span>
                        <span v-else><v-icon left>mdi-file-edit</v-icon>Editează rezultate</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="currentSession && currentSession.results && currentSession.results.length">
                    <v-col>
                      <v-data-table
                        :headers="isRaceSession ? computedRaceEventResultsTableHeaders : computedHotlapEventResultsTableHeaders"
                        :items="currentSession.results"
                        :group-by="event.results && event.results.length && event.results[0].Cursa ? 'Cursa' : null"
                        disable-pagination
                        hide-default-footer
                        :header-props="{ 'disable-sort': true }"
                      >
                        <template v-slot:top>
                          <v-container fluid>
                            <v-row>
                              <v-col
                                cols="12"
                                sm="6"
                                md="3"
                              >
                                <v-select
                                  :items="filterableCarClasses"
                                  v-model="resultsSelectedCarClass"
                                  label="Clasa"
                                  item-text="name"
                                  item-value="_id"
                                >
                                  <template v-slot:selection="{ item }">
                                    <kbd class="mr-1">{{item.acronym}}</kbd>
                                  </template>

                                  <template v-slot:item="{item, attrs, on}">
                                    <v-list-item v-on="on" v-bind="attrs">
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <v-row no-gutters align="center">
                                            <span>{{item.name}}</span>
                                            <v-spacer></v-spacer>
                                            <kbd class="mr-1">{{item.acronym}}</kbd>
                                          </v-row>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-select>
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>
                        <!-- RO afisajul rezultatelor -->
                        <template v-slot:[`item.finalOverallResult`]="{ item }">
                          <v-icon color="yellow accent-4" v-if="item.finalOverallResult == 1">mdi-trophy</v-icon>
                          <v-icon color="grey lighten-1" v-else-if="item.finalOverallResult == 2">mdi-trophy-variant</v-icon>
                          <v-icon color="brown lighten-2" v-else-if="item.finalOverallResult == 3">mdi-trophy-variant</v-icon>
                          <span class="font-weight-black" v-else>{{item.finalOverallResult}}</span>
                        </template>
<!-- ======================NOOB ZONE============================= -->
                        <template v-slot:[`item.car_icon_results`]="{ item }">                          
                          <v-img
                            v-if="carHasLiveryPreview(item.eventRsvp.car.raceNumber)"
                            :src="carLiveryPreviewURL(item.eventRsvp.car.raceNumber)"
                          width="100" 
                          height="40"
                          />
                        </template>
<!-- ===================NOOB ZONE================================ -->
                        <template v-slot:[`item.car`]="{ item }">
                          {{item.eventRsvp.car.carModel.title}}
                        </template>

                        <template v-slot:[`item.team`]="{ item }">
                          {{item.team.name}}
                        </template>
                        
                        <template v-slot:[`item.numberOfLaps`]="{ item }">
                          <v-dialog
                            v-model="carLapsDialog[item.finalOverallResult-1]"
                            max-width="700"
                            @click:outside="carLapsDialog[item.finalOverallResult-1] = false"
                            scrollable
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn min-width="32" class="px-1" text color="primary" v-on="on"><v-icon>mdi-timer</v-icon>{{item.laps.length}}</v-btn>
                            </template>
                            
                            <v-card class="pb-1">
                              <v-card-title>
                                <span class="headline">#{{ item.eventRsvp.car.raceNumber }} {{item.eventRsvp.car.carName}}</span>
                              </v-card-title>
                              <v-card-subtitle>
                                <span>Tururi</span>
                              </v-card-subtitle>
                              
                              <v-card-text style="max-height: 700px">
                                <v-data-table
                                  :headers="[
                                    {text:'#',value:'index',sortable:false,width:'5%'},
                                    {text:'Pilot',value:'driver',sortable:false,width:'45%'},
                                    {text:'Timp',value:'time',sortable:false,width:'45%'},
                                    {text:'Valid',value:'valid',sortable:false,width:'5%'}
                                  ]"
                                  :items="item.laps"
                                  disable-pagination
                                  hide-default-footer
                                  dense
                                >
                                  <template v-slot:item="{item, index}">
                                    <tr>
                                      <td>{{index + 1 }}</td>
                                      <td>{{ item.driver.profile.firstName }} {{ item.driver.profile.lastName }}</td>
                                      <td>{{ printLaptime (item.time) }}</td>
                                      <td>
                                        <v-icon color="green" v-if="item.valid">mdi-check</v-icon>
                                        <v-icon color="red" v-else>mdi-close</v-icon>
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                        </template>
                        <template v-slot:[`item.totalTime`]="{ item }">
                          <span>
                            {{printRaceResultTime (item.totalTime)}}
                            <span v-if="item.dsq">
                              (<strong class="red--text">DQ/DNF</strong>)
                            </span>
                            <span v-else-if="item.timePenalties.length>0">
                              (<strong class="red--text">+{{item.timePenalties.reduce((a,b) => a+b.amount, 0)}}s</strong>)
                            </span>
                          </span>
                        </template>
                        <template v-slot:[`item.crew`]="{ item }">
                          <kbd v-for="(carClass, kindex) in item.eventRsvp.car.classes" :key="kindex">{{carClass.acronym}}</kbd>#{{ item.eventRsvp.car.raceNumber }}
                          <template v-if="isTeamRace">
                            {{item.eventRsvp.car.carName}}
                            <br />
                          </template>
                          <span :key="driver._id" v-for="(driver, index) in item.drivers">
                            <kbd v-for="(driverClass, kindex) in getUserClasses(driver._id)" :key="kindex" class="mr-1">{{driverClass.acronym}}</kbd>
                            {{driver.profile.firstName.slice(0, 1)}}. {{driver.profile.lastName}}
                            <span v-if="index < item.drivers.length - 1">, </span>
                          </span>
                        </template>
                        <template v-slot:[`item.fastestLap`]="{ item }">
                          <span class="font-weight-bold">{{printBestLaptime (item.laps)}}</span> <span v-if="item.drivers.length > 1">({{printBestLaptimeDriver(item.laps)}})</span>
                        </template>
                        <template v-slot:[`item.penalties`]="{ item }">
                        <template v-if="item.dsq">
                          <span class="red--text font-weight-bold text-uppercase">DQ/DNF</span>
                        </template>
                        <template v-else-if="item.timePenalties.length + item.lapPenalties.length + item.gridPenalties.length == 0">
                          <v-btn min-width="32" class="px-1" text tile color="primary" disabled><v-icon>mdi-clipboard-text-off</v-icon>
                            {{item.timePenalties.length + item.lapPenalties.length + item.gridPenalties.length}}
                          </v-btn>
                        </template>
                          <v-dialog
                            v-model="carPenaltiesDialog[item.finalOverallResult-1]"
                            max-width="600"
                            @click:outside="carPenaltiesDialog[item.finalOverallResult-1] = false"
                            scrollable
                            v-else
                          >
                            <template v-slot:activator="{ on }">
                              <template v-if="item.timePenalties.length + item.lapPenalties.length + item.gridPenalties.length > 0">
                                <v-btn min-width="32" class="px-1" text tile color="red" v-on="on"><v-icon>mdi-clipboard-text</v-icon>{{item.timePenalties.length + item.lapPenalties.length + item.gridPenalties.length}}</v-btn>
                              </template>
                              <template v-else>
                                <v-btn min-width="32" class="px-1" text tile color="primary" v-on="on"><v-icon>mdi-clipboard-text</v-icon>0</v-btn>
                              </template>
                            </template>
                            <v-card class="pb-1">
                              <v-card-title
                                ><span class="headline">#{{ item.eventRsvp.car.raceNumber }} {{item.eventRsvp.car.carName}}</span></v-card-title
                              >
                              <v-card-subtitle>
                                <span>Penalizări</span>
                              </v-card-subtitle>
                              
                              <v-card-text style="max-height: 700px">
                                <v-row class="mx-1" dense v-if="isRaceSession">
                                  <v-col
                                    cols="12"
                                  >
                                    <v-card elevation="0" outlined v-if="item.timePenalties.length > 0">
                                      <v-card-title class="text-h6 font-weight-regular">Penalizări în timp</v-card-title>
                                      <v-list>
                                        <v-list-item v-for="(penalty, i) in item.timePenalties" :key="i">
                                          <v-list-item-action>
                                            <span class="red--text text-h6"><v-icon small left color="red">mdi-clock-plus-outline</v-icon>{{ penalty.amount }}s</span>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <span>{{penalty.description}} ({{penalty.driver.profile.firstName}} {{penalty.driver.profile.lastName}})</span>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-card>
                                    <v-card elevation="0" outlined v-if="item.lapPenalties.length > 0">
                                      <v-card-title class="text-h6 font-weight-regular">Penalizări în tururi</v-card-title>
                                      <v-list>
                                        <v-list-item v-for="(penalty, i) in item.lapPenalties" :key="i">
                                          <v-list-item-action>
                                            <span class="red--text text-h6"><v-icon small left color="red">mdi-flag-minus</v-icon>{{ penalty.amount }}</span>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <span>{{penalty.description}} ({{penalty.driver.profile.firstName}} {{penalty.driver.profile.lastName}})</span>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-card>
                                    <v-card elevation="0" outlined v-if="item.gridPenalties.length > 0">
                                      <v-card-title class="text-h6 font-weight-regular">Penalizări în poziții</v-card-title>
                                      <v-list>
                                        <v-list-item v-for="(penalty, i) in item.gridPenalties" :key="i">
                                          <v-list-item-action>
                                            <span class="red--text text-h6"><v-icon small left color="red">mdi-chevron-triple-down</v-icon>{{ penalty.amount }}</span>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <span>{{penalty.description}} ({{penalty.driver.profile.firstName}} {{penalty.driver.profile.lastName}})</span>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
  import axios from '@/plugins/axios';

export default {
  data: () => {
    return {
      eventResultsLeaderLaps: 0,
      filteredEntrylist: [],
      resultsSelectedCarClass: null,
      seasonRegistrationsTableHeaders: [
        { text: "#", value: "position", sortable: false },
        { text: "", value: "car_icon", sortable: false },
        { text: "Echipaj", value: "carName", sortable: false },
        { text: "Piloți", value: "drivers", sortable: false },
        { text: "Mașina", value: "carModel", sortable: false },
        { text: "Rezultat", value: "prettyPrequalTime", sortable: false, align: 'center' },
        { text: "Prezență", value: "rsvpStatus", sortable: false },
      ],
      carPenaltiesDialog: [],
      carLapsDialog: [],
      seasonRegistrationsTableData: [],
      rsvpDialogCar: undefined,
      rsvpCar: undefined,
      firstDriver: undefined,
      userAvailableCars: [],
      rsvpDialog: false,
      rsvpOpen: false,
      driverSuspended: false,
      rsvpDateGood: false,
      driverInRegisteredCar: false,
      prequalHashMap: {},
      bestPrequalTime: 0,
      bestPrequalTimeCar: '',
      carInfoDialog: {},
      DRIVER_STATUS_UNKNOWN: 0,
      DRIVER_STATUS_GREEN: 1,
      DRIVER_STATUS_TIME: 2,
      DRIVER_STATUS_RED: 3,
      DRIVER_STATUS_SUSPENDED: 4,
      DRIVER_STATUS_WILDCARD: 5,
      CAR_STATUS_UNKNOWN: 0,
      CAR_STATUS_GREEN: 1,
      CAR_STATUS_TIME: 2,
      CAR_STATUS_RED: 3,
      CAR_STATUS_SUSPENDED: 4,
      CAR_STATUS_WILDCARD: 5,
      sessionHashmap: {},
      storedResultsSession: undefined,
      currentSession: {
        type: { code: 'P' },
        results: []
      }
    };
  },
  methods: {
    ...mapActions("event", ["getEvent", "getEntrylist"]),
    ...mapActions("season", ["getSeason", "getUserSeasonClasses"]),
    ...mapActions("user", ["getUserPermissions"]),
    ...mapActions("app", ["showSnackbar"]),
    rsvpCarChanged () {
      // Look for first driver in event cars:
      let found = false;
      this.event.cars.forEach((ec) => {
        if (ec.car._id == this.rsvpCar._id) {
          let rsvpStatus =
            ec.drivers.filter((ecd) => {
              return ecd.driver._id == this.userinfo.id && ecd.registrationStatus;
            }).length > 0;

          if (rsvpStatus) {
            found = true;
            this.firstDriver = ec.drivers[0].driver;
            return;
          }
        }
      })

      // Fallback to season registration driver order if there is no EventRSVP just yet.
      if ( !found ) {
        this.firstDriver = this.rsvpDialogCar.drivers[0].driver;
      }
    },
    resultsCarClassFilter (_, __, item) {
      if (!this.resultsSelectedCarClass) {
        return true;
      }

      return item.eventRsvp.car.classes.map(cls => cls._id.toString()).includes(this.resultsSelectedCarClass.toString());
    },
    utf8To16 (text) {
      var byteArray = new Uint8Array(text.length * 2);
      for (var i = 0; i < text.length; i++) {
          byteArray[i*2] = text.charCodeAt(i) // & 0xff;
          byteArray[i*2+1] = text.charCodeAt(i) >> 8 // & 0xff;
      }

      return byteArray;
    },
    downloadEntrylist() {
      try {
        switch ( this.event.season.rules.simulator.acronym ) {
          case 'AC':
            axios({
              url: `/api/event/${this.event._id}/ac-download-entrylist`,
              method: 'GET',
              responseType: 'text'
            }).then((response) => {
              let blob = new Blob([response.data], { type: 'text/plain' })
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              
              link.download = 'entry_list.ini';
              
              link.click();
            });
            break
          case 'ACC':
            axios({
              url: `/api/event/${this.event._id}/acc-download-entrylist`,
              method: 'GET',
              responseType: 'json'
            }).then((response) => {
              const utf16le = this.utf8To16(JSON.stringify(response.data, null, 4));

              let blob = new Blob([utf16le], { type: 'application/json' })
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              
              link.download = 'entrylist.json';
              
              link.click();
            });
            break
        }
      } catch {
        console.log ("Could not download entrylist :-(")
      }
    },
    getUserClasses(driverId) {
      if (this.userSeasonClasses) {
        return this.userSeasonClasses.filter(item => item.user == driverId).map(uc=>uc.classes).flat();
      }
      
      return [];
    },
    isUserWildcard(userId) {
      if ( this.event ) {
        return this.event.wildcards.includes(userId);
      }
      
      return false;
    },
    timelineColor (session) {
      switch (session.type.code) {
        case 'P': return 'light-blue darken-3'
        case 'Q': {
          return session.official ? 'deep-orange darken-4' : 'cyan darken-3'
        }
        case 'R': return 'green darken-3'
        case 'O':
        case 'A': return 'blue-grey darken-4'
      }
    },
    printDate(date) {
      let m = new Date(date);

      let result =
        m.getUTCFullYear() + "-" + ("00" + (m.getUTCMonth() + 1)).slice(-2) + "-" + ("00" + m.getUTCDate()).slice(-2);

      result += ", " + ("00" + m.getHours()).slice(-2) + ":" + ("00" + m.getMinutes()).slice(-2);

      return result;
    },
    carLiveryFileName (raceNumber) {
      const seasonPrefix = this.event.season.code ? `RLR_${this.event.season.code}` : 'RLR';
      return `${seasonPrefix}_${raceNumber}.png`;
    },
    carHasLiveryPreview (raceNumber) {
      try {
        // eslint-disable-next-line no-unused-vars
        const fileName = require("../../public/car_ico/" + this.carLiveryFileName(raceNumber));
        return true;
      } catch {} // eslint-disable-line no-empty
      
      return false;
    },
    carLiveryPreviewURL (raceNumber) {
      return `/car_ico/${this.carLiveryFileName(raceNumber)}`;
    },
    printLaptime(time) {
      if (time == 2147483647) {
        return "---";
      }

      let t = "";
      let minutes = Math.floor(time / 60000);
      // minutes = ("00"+(minutes)).slice(-2)

      let seconds = Math.floor(time / 1000) % 60;
      seconds = ("00" + seconds).slice(-2);

      let milliseconds = time % 1000;
      milliseconds = ("000" + milliseconds).slice(-3);

      t += minutes + ":" + seconds + "." + milliseconds;

      return t;
    },
    printRaceResultTime(time) {
      let t = "";

      let hours = Math.floor(time / 3600000);

      if (hours) {
        t += hours;
        t += ":";
      }

      let minutes = Math.floor(time / 60000) % 60;
      if (hours) {
        t += ("00" + minutes).slice(-2);
      } else {
        t += minutes;
      }

      t += ":";

      let seconds = Math.floor(time / 1000) % 60;
      t += ("00" + seconds).slice(-2);

      t += ".";

      let milliseconds = time % 1000;
      t += milliseconds = ("000" + milliseconds).slice(-3);

      return t;
    },
    printBestLaptime (laps) {
      return this.printLaptime(laps.reduce ((a, b) => a > b.time && b.valid ? b.time : a, 2147483647));
    },
    printBestLaptimeDriver (laps) {
      let driver = '';
      let bestlap = 2147483647;
      
      laps.forEach (lap => {
        if (lap.time < bestlap) {
          bestlap = lap.time;
          driver = lap.driver.profile.firstName.slice(0,1) + lap.driver.profile.lastName.slice(0,1);
        }
      })
      
      return driver;
    },
    updatePrequalHashMap() {
      if (!this.event.season.rules.prequalEnabled) {
        this.prequalHashMap = {};
      }

      this.event.prequal.results.forEach((result) => {
        this.prequalHashMap[`${result.driver}_${result.carModel}`] = result;
      });
    },
    showCar(car_id) {
      this.carInfoDialog[car_id] = true;
    },
    closeCar(car_id) {
      this.carInfoDialog[car_id] = false;
    },
    updateEventResultsTableData() {
      this.event.results = this.event.results.map((result) => {
        result.Cursa = result.race;
        return result;
      });
    },
    async updateSeasonRegistrationsTableData() {
      await this.getEntrylist(this.event._id);
      
      this.filteredEntrylist = [];
      this.carInfoDialog = {};
      
      this.entrylist.splits.forEach(split => {
        split.entries.forEach(entry => {
          entry.Split = split.title;
          this.filteredEntrylist.push(entry);
          this.carInfoDialog[entry.car._id] = false;
        })
      })
    },
    updateUserAvailableCars() {
      this.userAvailableCars = [];

      if (this.isLoggedIn) {
        this.event.season.registrations.forEach((registration) => {
          registration.cars.forEach((car) => {
            let carRegistrationStatus = car.drivers.reduce((a, c) => {
              return a && c.registrationStatus;
            });
            if (
              car.drivers
                .map((carDriver) => {
                  return carDriver.driver._id;
                })
                .includes(this.userinfo.id) &&
              carRegistrationStatus
            ) {
              this.userAvailableCars.push(car);
            }
          });
        });
      }
    },
    updateRSVPCar() {
      this.rsvpDialogCar = "";
      this.rsvpCar = "";

      if (this.isLoggedIn) {
        this.event.season.registrations.forEach((registration) => {
          registration.cars.forEach((c) => {
            c.drivers.forEach((carDriver) => {
              if (carDriver.driver._id == this.userinfo.id) {
                let rsvpStatus = false;

                this.event.cars.forEach((ec) => {
                  if (ec.car._id == c._id) {
                    rsvpStatus =
                      ec.drivers.filter((ecd) => {
                        return ecd.driver._id == this.userinfo.id && ecd.registrationStatus;
                      }).length > 0;

                    if (rsvpStatus) {
                      this.rsvpDialogCar = c;
                      this.rsvpCar = c;
                      this.rsvpCarChanged(); // Trigger first driver refresh
                      return;
                    }
                  }
                });
              }
            });

            if (this.rsvpCar) {
              return;
            }
          });

          if (this.rsvpCar) {
            return;
          }
        });
      }
    },
    async sendRSVP() {
      const carId = this.rsvpCar ? this.rsvpCar._id : "";
      const firstDriverId = this.firstDriver ? this.firstDriver._id : "";

      let newRSVP = await this.$http.post(`/api/event/${this.event._id}/rsvp`, { car: carId, firstDriver: firstDriverId });
      this.showSnackbar({
        timeout: 7000,
        content: newRSVP.data.data,
        color: newRSVP.data.status == "200" ? "success" : "error",
        value: true,
      });

      await this.getEvent(this.$route.params.id);
      await this.updateSeasonRegistrationsTableData();
    },
    resetRSVPCar() {
      this.rsvpCar = undefined;
      this.rsvpDialogCar = undefined;
      this.rsvpDialog = false;

      this.sendRSVP();
    },
    confirmRSVP() {
      this.rsvpCar = this.rsvpDialogCar;
      this.rsvpDialog = false;
      this.sendRSVP();
    },
    cancelRSVPDialog() {
      this.rsvpDialogCar = this.rsvpCar;
      this.rsvpDialog = false;
    },
    updateRSVPOpen() {
      const rsvpStart = new Date(this.event.rsvpStart);
      const rsvpEnd = new Date(this.event.rsvpEnd);
      const dateCriteria = rsvpStart < Date.now() && rsvpEnd > Date.now();

      this.rsvpDateGood = dateCriteria;
      let driverCriteria = false;

      this.event.season.registrations.forEach((registration) => {
        registration.cars.forEach((car) => {
          let carRegistrationStatus = car.drivers.reduce((a, c) => {
            return a && c.registrationStatus;
          });
          if (
            car.drivers
              .map((carDriver) => {
                return carDriver.driver._id;
              })
              .includes(this.userinfo.id) &&
            carRegistrationStatus
          ) {
            driverCriteria = true;
          }
        });
      });

      this.rsvpOpen = dateCriteria && driverCriteria && !this.driverSuspended;
      this.driverInRegisteredCar = driverCriteria;
    }
  },
  computed: {
    ...mapState("event", ["event", "entrylist"]),
    ...mapState("season", ["season", "userSeasonClasses"]),
    ...mapGetters("user", ["userPermissions"]),
    ...mapGetters("auth", ["userinfo", "isLoggedIn"]),
    isTeamRace () {
      return !!(this.season?.rules?.maxDriversPerCar > 1)
    },
    isPrequalEnabled () {
      return !!this.season?.rules?.prequalEnabled
    },
    computedSeasonRegistrationsTableHeaders () {
      return this.seasonRegistrationsTableHeaders.filter(header => {
        if (header.value === 'drivers' ) {
          return {...header, text: 'Pilot'}
        }

        if (header.value === 'car_icon') {
          if (this.$vuetify.breakpoint.smAndUp) {
            return header
          } else {
            return false
          }
        }

        return header
      }).filter(header => {
        if (header.value === 'prettyPrequalTime') {
          return this.isPrequalEnabled
        }

        if (header.value === 'carName') {
          return this.isTeamRace
        }

        return true
      });
    },
    computedRaceEventResultsTableHeaders () {
      return this.raceEventResultsTableHeaders.map(header => {
        if (header.value === 'crew') {
          return {...header, text: 'Pilot'}
        }

        return header
      })
    },
    computedHotlapEventResultsTableHeaders () {
      return this.hotlapEventResultsTableHeaders.map(header => {
        if (header.value === 'crew') {
          return {...header, text: 'Pilot'}
        }

        return header
      })
    },
    carNeedsFirstDriverSet () {
      const simulatorNeedsFirstDriverSet = this.event ? this.event.season.rules.simulator.acronym=='ACC' : false;
      const moreThanOneDriver = this.rsvpDialogCar && this.rsvpDialogCar.drivers ? this.rsvpDialogCar.drivers.length > 1 : false;

      return simulatorNeedsFirstDriverSet && moreThanOneDriver;
    },
    rsvpAvailableDrivers() {
      if (this.rsvpDialogCar && this.rsvpDialogCar.drivers) {
        return this.rsvpDialogCar.drivers.map(cd => cd.driver) || [];
      }

      return [];
    },
    filterableCarClasses() {
      return [{_id: null, acronym: 'O', name: 'Open'}, ...this.event.season.carClasses.map(cls => cls.obj)]
    },
//================NOOB====================================================================================
    raceEventResultsTableHeaders() {return [
      { text: "Poz.", value: "finalOverallResult", sortable: false, width: "5%", align: 'center' },
      { text: "", value: "car_icon_results", sortable: false, width: "10%", align: 'center'  },
      { text: "Echipaj", value: "crew", sortable: false, width: "30%", filter: this.resultsCarClassFilter },
      { text: "Timp total", value: "totalTime", sortable: false, width: "20%", align: 'center' },
      { text: "Tururi", value: "numberOfLaps", sortable: false, width: "10%", align: 'center' },
      { text: "Fastest lap", value: "fastestLap", sortable: false, width: "20%", align: 'center' },
      { text: "Penalizări (post-cursă)", value: "penalties", sortable: false, width: "5%", align: 'center' },
    ]},
    hotlapEventResultsTableHeaders() {return [
      { text: "Poz.", value: "finalOverallResult", sortable: false, width: "5%", align: 'center' },
      { text: "", value: "car_icon_results", sortable: false, width: "10%", align: 'center'  },
      { text: "Echipaj", value: "crew", sortable: false, width: "40%", filter: this.resultsCarClassFilter },
      { text: "Tururi", value: "numberOfLaps", sortable: false, width: "10%", align: 'center' },
      { text: "Fastest lap", value: "fastestLap", sortable: false, width: "30%", align: 'center' },
      { text: "Penalizări (post-cursă)", value: "penalties", sortable: false, width: "5%", align: 'center' },
    ]},
//===========================NOOOB========================================================================
    canUserEditResults () {
      if ( this.event.season && this.event.season.permissions && this.event.season.permissions.editRaceResults ) {
        return this.userPermissions.filter(perm => this.event.season.permissions.editRaceResults.includes(perm)).length>0;
      }
      
      return false;
    },
    canUserDownloadEntrylist () {
      const acceptableSimulators = [ "AC", "ACC" ]
      
      try {
        if (acceptableSimulators.indexOf ( this.event.season.rules.simulator.acronym ) === -1) {
          return false
        }
        
        return this.userPermissions.filter(perm => this.event.season.permissions.downloadEntrylists.includes(perm)).length>0;
      } catch (e) {
        console.log (e.message)
        return false
      }
    },
    isHotlapSession () {
      return this.currentSession.type.code == 'Q' || this.currentSession.type.code == 'P'
    },
    isRaceSession () {
      return this.currentSession.type.code == 'R'
    },
    tab: {
      set(tab) {
        if (tab == this.tab) {
          return;
        }
        
        this.$router.replace({ query: { ...this.$route.query, tab } });
        
        if ( this.tab != "results") {
          if (this.sessionresults) {
            this.sessionresults = undefined;
          }
        } else {
          this.sessionresults = this.storedResultsSession || this.officialSessions[0]._id
        }
      },
      get() {
        if (this.$route.query.tab) return this.$route.query.tab;
        return "info";
      },
    },
    sessionresults: {
      set(sessionresults) {
        if (sessionresults == this.sessionresults) {
          return;
        }
        
        if ( sessionresults !== undefined ) {
          this.storedResultsSession = sessionresults;
        }
        
        this.$router.replace({ query: { ...this.$route.query, sessionresults } });
        
        this.currentSession = this.sessionHashmap[this.sessionresults];
      },
      get() {
        if (this.$route.query.sessionresults) return this.$route.query.sessionresults;
        return "";
      }
    },
    officialSessions() {
      if ( !this.event || !this.event.sessions || this.event.sessions.length < 1 ) {
        return [];
      }
      
      return this.event.sessions.filter ( s => s.official )
    }
  },
  async mounted() {
    await this.getEvent(this.$route.params.id);
    await this.getSeason(this.event.season._id);
    await this.updatePrequalHashMap();
    await this.getUserPermissions();
    
    await this.getUserSeasonClasses(this.event.season._id);

    await this.updateSeasonRegistrationsTableData();
    await this.updateEventResultsTableData();
    
    // Update session hashmap
    this.event.sessions.forEach ( session => {
      this.sessionHashmap[session._id] = session
    })
    
    // Make sure to set sessionresults if we're loading straight into the results tab:
    if ( this.$route.query.tab && this.$route.query.tab == 'results' ) {      
      this.sessionresults = this.storedResultsSession || this.officialSessions[0]._id
    }
    
    this.storedResultsSession = this.sessionresults;
    this.currentSession = this.sessionHashmap[this.sessionresults];

    this.updateRSVPOpen();

    if (this.isLoggedIn) {
      await this.updateUserAvailableCars();
      await this.updateRSVPCar();

      // Check if user is suspended from this event:
      let suspendedDrivers = this.event.suspendedDrivers || [];
      this.driverSuspended = suspendedDrivers.map(sd => sd.toString()).includes(this.userinfo.id.toString())
    }

    this.eventResultsLeaderLaps = this.event.results[0] ? this.event.results[0].numLaps : 0;

    if (this.event.results[0] && this.event.results[0].race) {
      // daca am race, adica eventul are 2 curse
      this.eventResultsLeaderLaps = [];
      this.eventResultsLeaderLaps[this.event.results[0].race] = this.event.results[0].numLaps; // tururi lider prima cursa
      for (let i = this.event.results.length - 1; i >= 0; i--) {
        this.eventResultsLeaderLaps[this.event.results[i].race] = this.event.results[i].numLaps;
      }
    }
  },
};
</script>

<style>
.v-row-group__header .text-start button:nth-of-type(2) {
  display: none;
}
.v-data-table {
  line-height: 1.2!important;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 32px!important;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child {
  padding-bottom: 16px;
}
</style>
