<template>
  <div>
    <!-- Main Card - Displayed initially -->
    <v-card v-if="!showInfoCard" outlined>
      <v-img
        src="@/assets/standings_static/AC_S3_Header.jpg"
        :height="$vuetify.breakpoint.smAndDown ? '90px' : '200px'"
        gradient="to top right, rgba(0,0,0,.5), rgba(255,165,0,.5)"
        class="align-end"
      >
        <v-card-title class="white--text pb-0 d-block">
          <p
            :class="$vuetify.breakpoint.smAndDown ? 'font-weight-black text-h5 text-wrap d-block mb-0' : 'font-weight-black text-h4 text-wrap d-block mb-0'"
            style="word-break: normal !important"
          >
            Sezon 3 - Assetto Corsa [rezumat]
          </p>
          <p :class="$vuetify.breakpoint.smAndDown ? 'body-2 mt-0 blue--text' : 'body-2 mt-0 blue--text '">Assetto Corsa</p>
          <p class="body-2 text-justify d-none d-md-block grey--text text--lighten-3" style="word-break: normal !important">
            Odata cu sosirea lui Victor Nicolae si avantul organizatoric pe care l-a adus, ne lansam intr-o noua serie de competitii virtuale. Migram de la LFS la Assetto Corsa.
          </p>
        </v-card-title>
      </v-img>

      <v-card-text>
        <v-container>
          <v-row>
            <!-- Left Column -->
            <v-col cols="6">
              <!-- Row 1: Competition Duration -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-calendar-month</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Durata competiției</v-list-item-title>
                    <span>12 mai 2019 <v-icon small>mdi-arrow-right</v-icon> 30 iunie 2019</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 2: Cars -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-car</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Mașina</v-list-item-title>
                    <span>Mazda MX5 Cup</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 3: Participation -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-account-group</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Participare</v-list-item-title>
                    <span>26 mașini înscrise</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <!-- Right Column -->
            <v-col cols="6">
              <!-- Row 1: Champion -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon style="color: #daa520;">mdi-trophy</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Campion</v-list-item-title>
                    <span>Alex Cascatau</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 2: Format -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-timer</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Etape</v-list-item-title>
                    <span>Cele mai bune 7 din 8</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 3: Prizes -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-car-brake-parking</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Premii</v-list-item-title>
                    <span>Prietenii care incă durează și astăzi (unele).</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

            </v-col>
          </v-row>
        </v-container>
</v-card-text>

      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="6" sm="3">
            <v-btn dark color="green darken-1" @click="showInfoCard = true">
              CLASAMENT <v-icon right>mdi-information-outline</v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6" sm="3" :align="$vuetify.breakpoint.smAndUp ? 'right' : 'center'">
            <v-btn dark color="blue darken-1" href="https://forum.simrace.ro/index.php?forums/arhivele-nationale.55/" target="_blank">
              Arhive <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <!-- New Card with Detailed Information and an Image - Shown when "INFO" is clicked -->
    <v-card v-else outlined class="ma-4 pa-4">
      <!-- Add image to the info card -->
      <v-card-title class="headline">Detalii competiție</v-card-title>
      <v-card-text style="text-align: justify;">
        12/mai : ETAPA 1 - Donington National<br>
        19/mai : ETAPA 2 - Brands Hatch Indy<br>
        26/mai : ETAPA 3 - Nurburgring GP Sprint<br>
        02/iun : ETAPA 4 - Motorpark Romania<br>
        09/iun : ETAPA 5 - Bathurst<br>
        16/iun : ETAPA 6 - Bridgehampton<br>
        23/iun : ETAPA 7 - Watkins Glen<br>
        30/iun : ETAPA 8 - Laguna Seca<br>
      </v-card-text>
      <v-card-text style="text-align: justify;">
        DEBUT DE SEZON (by Vic): Sezonul 3 din Racing League Romania a debutat în weekend-ul 11-12 mai, aducând totodată câteva schimbări importante în formatul competiției. Încă o dată au dispărut cai putere, odată cu trecerea la o nouă mașină: Mazda MX-5 Cup. Viteza scăzută a adus cu sine și dueluri mai strânse ca niciodată combinate cu o dinamică accentuată a curselor; au fost aduse și schimbări ale regulamentului în ceea ce privește penalizările iar toate acestea au concluzionat într-o etapă de debut disputată echilibrat între cei mai rapizi piloți din RLR.
      </v-card-text>
      <v-card-text style="text-align: justify;">
        <p>FINAL DE SEZON (by Luc): Desi campionul si-a facut public titlul inaintea oficializarii de catre comisie :lol:, iata si clasamentul OFICIAL. Fara surprize prea mari, C.Alex a luat cupa in Sezonul 3. D.Radu a incercat sa ii dea ceva emotii dar psihicul lui Alex nu s-a lasat impresionat. Si mentionandu-l pe D.Radu, impresionanta evolutia fata de sezonul trecut! Daca atunci termina pe un discret loc 8, sezonul acesta a avut sanse reale la titlu, fantastic. Vom vedea in sezonul urmator daca a fost doar o forma trecatoare, un combo ce i-a venit manusa sau chiar e serios! Incheiem podiumul cu un alt nume nou, D.Dan. La doar 4p distanta de urmaritor (subsemnatul), dar a reusit. Merita mentionat cu ocazia asta si faptul ca toate pronosticurile au fost date peste cap. Dupa doua prime sezoane in care Top3 a fost identic, "vedetele" au fost pur si simplu spulberate de noua generatie de piloti, felicitari! Si noua, dar mai ales lor!</p>
        Felicitari tuturor participantilor, mi-a facut o deosebita placere sa ne alergam etapa de etapa. Sper ca si voua si ne revedem si mai motivati in Sezonul 4. Inainte de a va spune vacanta placuta, cateva note de picior (greu pe acceleratie #badumtssss):
        <br>
        1/ Jos palaria in fata singurilor 2 piloti care nu au ratat nicio etapa sezonul asta: D.Dan si S.Cristian;
        <br>
        2/Am batut recodul sezonului trecut - am avut 24 de piloti care au adunat si puncte, din 26 inscrisi;
        <br>
        3/Ramaneti aproape de forum/FB, pentru ca vom avea curse de fun pe timpul verii (va puteti invita si prietenii);
        <br>
        4/Cu o singura participare, D.Dracea reuseste sa adune mai multe puncte decat @MihaiMese sau @Daniel Moldovan sau @M.Gabriel sau @Catalin Zota :whistle: ..si noroc cu Mihnea, ca lista era si mai lunga.
      </v-card-text>
      <v-img
        src="@/assets/standings_static/AC_S3_Final.jpg"
        class="align-end"></v-img>
        
      <v-card-actions>
        <v-btn color="blue darken-1" @click="showInfoCard = false">
          <v-icon left>mdi-arrow-left</v-icon> Înapoi
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AC_Sezon3',
  data() {
    return {
      showInfoCard: false, // Track whether the info card is shown
    };
  },
};
</script>

<style scoped>
/* Add any custom styles here if needed */
</style>