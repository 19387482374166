<template>
  <div>
    <!-- Main Card - Displayed initially -->
    <v-card v-if="!showInfoCard" outlined>
      <v-img
        src="@/assets/standings_static/AC_S2_Header.png"
        :height="$vuetify.breakpoint.smAndDown ? '90px' : '200px'"
        gradient="to top right, rgba(0,0,0,.5), rgba(255,165,0,.5)"
        class="align-end"
      >
        <v-card-title class="white--text pb-0 d-block">
          <p
            :class="$vuetify.breakpoint.smAndDown ? 'font-weight-black text-h5 text-wrap d-block mb-0' : 'font-weight-black text-h4 text-wrap d-block mb-0'"
            style="word-break: normal !important"
          >
            Sezon 2 - Assetto Corsa [rezumat]
          </p>
          <p :class="$vuetify.breakpoint.smAndDown ? 'body-2 mt-0 blue--text' : 'body-2 mt-0 blue--text '">Assetto Corsa</p>
          <p class="body-2 text-justify d-none d-md-block grey--text text--lighten-3" style="word-break: normal !important">
            Odata cu sosirea lui Victor Nicolae si avantul organizatoric pe care l-a adus, ne lansam intr-o noua serie de competitii virtuale. Migram de la LFS la Assetto Corsa.
          </p>
        </v-card-title>
      </v-img>

      <v-card-text>
        <v-container>
          <v-row>
            <!-- Left Column -->
            <v-col cols="6">
              <!-- Row 1: Competition Duration -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-calendar-month</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Durata competiției</v-list-item-title>
                    <span>04 februarie 2019 <v-icon small>mdi-arrow-right</v-icon> 25 martie 2019</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 2: Cars -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-car</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Mașina</v-list-item-title>
                    <span>Porsche Cayman GT4 Clubsport</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 3: Participation -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-account-group</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Participare</v-list-item-title>
                    <span>26 mașini înscrise</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <!-- Right Column -->
            <v-col cols="6">
              <!-- Row 1: Champion -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon style="color: #daa520;">mdi-trophy</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Campion</v-list-item-title>
                    <span>Victor Nicolae</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 2: Format -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-timer</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Etape</v-list-item-title>
                    <span>Cele mai bune 7 din 8</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider inset></v-divider>

              <!-- Row 3: Prizes -->
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-car-brake-parking</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="font-weight-bold">Premii</v-list-item-title>
                    <span>Prietenii care incă durează și astăzi.</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

            </v-col>
          </v-row>
        </v-container>
</v-card-text>

      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="6" sm="3">
            <v-btn dark color="green darken-1" @click="showInfoCard = true">
              CLASAMENT <v-icon right>mdi-information-outline</v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6" sm="3" :align="$vuetify.breakpoint.smAndUp ? 'right' : 'center'">
            <v-btn dark color="blue darken-1" href="https://forum.simrace.ro/index.php?forums/arhivele-nationale.55/" target="_blank">
              Arhive <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <!-- New Card with Detailed Information and an Image - Shown when "INFO" is clicked -->
    <v-card v-else outlined class="ma-4 pa-4">
      <!-- Add image to the info card -->
      <v-img
        src="@/assets/standings_static/AC_S2_Calendar.jpg"
        class="align-end"></v-img>
      <v-card-title class="headline">Detalii competiție</v-card-title>
      <v-card-text style="text-align: justify;">
        DEBUT DE SEZON (by Luc): Sezonul 2 din Racing League Romania a debutat cu batalii 1 la 1, batalii multi cu multi, lupte curate, lupte discutabile, adrenalina, dezamagire, amuzament, surprindere, #viclenie, incantare, experienta, demonstratie, #subordonare, drept la replica, fair-play. Lista pare prea mare pentru o singura etapa, nu ? Ei bine, nu este. Chiar au fost de toate. Toti pilotii au plecat cu sperantele specifice inceputului de sezon. Unora le-a iesit. Altora mai putin. Dar asta e frumusetea curselor, adaptarea la conditii schimbatoare, adaptarea strategiilor pe baza experientei acumulate si mai ales refuzul de a renunta prea usor. Desigur ca alergam "pentru ca se ne distram", dar nu poate fi nici ca o plimbare de duminica, ar deveni rapid plictisitor. Necesita un minim de implicare, iar pilotii RLR au aratat-o cu varf si indesat luni seara.
      </v-card-text>
      <v-card-text style="text-align: justify;">
        FINAL DE SEZON (by Luc): Si s-a incheiat si Sezonul 2. Care a marcat puncte de progres la absolut toate capitolele, comparativ cu Sezonul 1. Asta demonstreaza, daca mai era nevoie, ca un cadru organizat va fi mereu mai atractiv si mai util decat ideea curselor publice.
        <br>
        Avem si o constanta, si aceea este "Campionul". #01 N.Victor reuseste sa isi pastreze titlul, in pofida unui start de sezon care plantase cateva semne de intrebare in mintile fanilor. De altfel, privind acum topicul primului sezon, top 3 este neschimbat: P1 #01 N.Victor | P2 #78 C.Luci | P3 #09 C.Luchian. 
        <br>
        Cu toate astea, noi piloti ni s-au alaturat si par sa promita ca lucrurile nu se vor mai transa la fel de simplu in Sezonul 3. Judecand dupa ritm in goana dupa best laps, sunt multi piloti ce au potentialul sa scuture serios podiumul. Sa vedem daca il vor valorifica :). Nu e nevoie sa ma credeti pe cuvant, e de ajuns sa priviti locurile P4-P7 cel putin. Fara a mai aminti de cativa sleeperi, care au terminat ceva mai jos in clasament din motive de "viata" :). Marele absent a fost poate #82 S.Gabriel, care nu de putine ori a reusit sa puna presiune reala pe locul 1 cu ritmul sau alert dar mai ales extrem de constant. Din nou tin sa remarc progresul facut de 2 piloti pe care ii urmaresc din umbra: #76 B.Marton si #13 R.Iulian. In ciuda unor evolutii care poate le-au testat nervii uneori, au continuat sa se antreneze si sa alerge. Si asta a facut ca spre final de compionat sa vedem timpi cu mult scazuti fata de debut. Se poate deci!
        <br>
        Hai cu un mic shoutout si pentru #11 M.Gabriel, care a revenit in simracing dupa o pauza de 7 ani, si care in ciuda a numeroase #driverexcuses, termina pe un onorabil loc 12. Exact midpack.
        <br><br>
        Privind clasamentul in ansamblu, diferentele intre piloti par ceva mai mari decat ar fi in realitate. A doua jumatate a clasamentului a fost mult mai mult influentata de absente, decat de rezultate slabe per se. Asa ca daca mi s-ar permite o remarca (o dorinta?) pentru sezonul urmator ar fi doua :D
        <br>
        1/ sa lucram la prezenta.
        <br>
        2/ sa lucram la "awareness" in trafic (cine imi da o traducere corespunzatoare contextului pentru "awareness", primeste secret bonus points in clasamentul din S3 :D ).
        <br><br>
        Clasamentul de mai jos listeaza pilotii care au alergat macar 1 cursa cu noi. Pentru ca altfel, am avut 26 inscrisi! Gasesc absolut fantastic ca reusim sa ne "strangem". Indiferent de platforma preferata, ca simracer de Romania, este esential sa stim in primul rand unii de altii :).
        <br><br>
        Felicitari tuturor participantilor, felicitari castigatorului, si sper ca distractia de care ati avut parte sa fi meritat macar cele 5 minute pe care le-ati "irosit" pentru inscriere.
      </v-card-text>
      <v-img
        src="@/assets/standings_static/AC_S2_Final.jpg"
        class="align-end"></v-img>
        
      <v-card-actions>
        <v-btn color="blue darken-1" @click="showInfoCard = false">
          <v-icon left>mdi-arrow-left</v-icon> Înapoi
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AC_Sezon2',
  data() {
    return {
      showInfoCard: false, // Track whether the info card is shown
    };
  },
};
</script>

<style scoped>
/* Add any custom styles here if needed */
</style>